export const MERCHANTS = [
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-0.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-0.svg',
    merchantName: 'カラコン通販サイト『モアコンタクト』',
    destinationURL: 'https://i.morecon.jp/jnh',
    category: [4],
    content:
      '“日本最大級”カラコン約2,000点以上の品揃え。お得キャンペーンや割引クーポン盛りだくさん。毎週末ポイント10倍セール開催中。カラコンをお得に買うなら、今すぐモアコンをチェック。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-1.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-1.svg',
    merchantName: 'ふわっち',
    destinationURL: 'https://whowatch.tv/',
    category: [6],
    content:
      'ふわっちは、配信者と視聴者が動画を通じて、双方でコミュニケーションを楽しむ「みんなのライブ配信」サービス。誰でも気軽に配信ができ、視聴者はライブ配信の閲覧とともにコメントやアイテムを使用して配信を盛り上げることができます。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-2.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-2.svg',
    merchantName: 'スペースマーケット',
    destinationURL:
      'https://www.spacemarket.com/?utm_source=partner&utm_medium=referral&utm_campaign=paidy&utm_term=20211101',
    category: [7],
    content:
      'レンタルスペース掲載数国内No.1のスペースマーケット。パーティー、女子会、誕生日会、デートに使える「おうちスペース」や「貸し会議室」「撮影スタジオ」など、さまざまな種類の部屋を16,000件以上掲載。期間限定キャンペーン開催。今すぐチェック。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-3.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-3.svg',
    merchantName: 'magi',
    destinationURL: 'https://magi.camp/',
    category: [7, 6],
    content:
      '累計40万インストール突破の国内最大トレカ専用フリマアプリ。ポケカ、遊戯王、デュエマなどの人気タイトルの希少カードを多数取扱中。限定招待コード「CZGFCY」を入力して1,000円以上のお買い物で使える500円割引クーポンをGETしよう。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-4.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-4.svg',
    merchantName: 'GLADD',
    destinationURL:
      'https://gladd.jp/?utm_source=paidy&utm_medium=reference&utm_campaign=paidy_autumn_cp_lp_20211101_1121',
    category: [2, 3, 5, 4],
    content:
      '秋冬ファッション、インテリア、コスメ、フードなど、知らなかったブランドや掘り出し物と出会える豊富な商品ラインナップ。最大90％OFFセール、新規登録クーポンや会員限定キャンペーンなど、GLADDならではのお得なお買い物体験をお楽しみください。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-5.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-5.png',
    merchantName: 'G123',
    destinationURL: 'https://g123.jp/',
    category: [6],
    content:
      'ダウンロードも会員登録も必要なし、スマホ・タブレット・パソコンのWEBブラウザ上で、厳選された高クオリティのゲームが楽しめるサービスです。戦略SLG「ビビッドアーミー」や、RPG「精霊幻想記アナザーテイル」などを配信中。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-6.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-6.svg',
    merchantName: '郵便局のネットショップ',
    destinationURL: 'https://www.shop.post.japanpost.jp/shop/a/apaidy-cam/',
    category: [5, 3, 1, 4, 7],
    content:
      '切手やはがき、レターパックはもちろん、お歳暮ギフトや郵便局限定のおせち、オリジナルキャラクターグッズ、フレーム切手など、バラエティ豊かな商品が勢揃い。今ならお歳暮ギフトが早割価格で購入できます。この機会をお見逃しなく。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-7.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-7.png',
    merchantName: 'ファッション夢展望',
    destinationURL: 'https://www.dreamvs.jp/shop/default.aspx',
    category: [2],
    content:
      'ワンピースやスカートなどの洋服は丈を選べて、靴はサイズも豊富。テイストも量産型・地雷系・闇属性・ガーリーなど何でも揃う、可愛いアイテムならお任せのブランド。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-8.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-8.svg',
    merchantName: 'サウンドハウス',
    destinationURL: 'https://www.soundhouse.co.jp/',
    category: [5],
    content:
      '楽器、音響、照明機器に特化した国内最大規模のネット通販サイト。世界のトップブランドをはじめ、プライベートブランドなど、10万点以上の商品を良心的な価格で販売中です。さらにお得なクーポンやポイント還元キャンペーンも開催中。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-9.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-9.svg',
    merchantName: 'WEGO ONLINE STORE',
    destinationURL:
      'https://wegoec.jp/shop/default.aspx?utm_source=paidy&utm_medium=referral&utm_campaign=paidy_202111',
    category: [2],
    content:
      'WEGO（ウィゴー）は、東京・原宿のストリートから発信される幅広いスタイルをメインに、ユーズドライクな着こなしをMIXした、個性的なアレンジで最新のトレンドを提案します。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-10.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-10.svg',
    merchantName: 'ＪＯＧＧＯ',
    destinationURL:
      'https://joggo.jp/?utm_source=paidy&utm_medium=referral&utm_campaign=paidy202111',
    category: [5, 7],
    content:
      'レザー・革製品のオーダーメイドならＪＯＧＧＯ。プレゼントに最適な革財布と革小物を、14色のレザーからデザインできます。あなたの「想い」を込めた、世界に１つだけのオリジナルアイテムをつくってみませんか？',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-11.jpg',
    imgLogo: '/campaign/merchant-logos/merchant-logo-11.svg',
    merchantName: 'Mew contact',
    destinationURL:
      'https://contact.mewshop.jp/?utm_source=paidy&utm_medium=paidy_cp&utm_campaign=paidy_cp_202111',
    category: [4],
    content:
      'フリュー株式会社のカラコン公式通販サイト。送料無料で、対象商品は15時まで当日発送。商品はすべて国内承認レンズ。度あり、ワンデー、ナチュラルカラコンなど、豊富な品揃え。毎回同梱クーポンでカラコンがお得に購入できます。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-12.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-12.png',
    merchantName: 'カードショップ-遊々亭-',
    destinationURL: 'https://yuyu-tei.jp/t/motfZX',
    category: [6],
    content:
      'トレーディングカードの通販サイト。業界トップの品揃えとハイクオリティなサービス、全国一律送料無料で即日発送にも対応。配送中の傷み防止を考慮したオリジナル梱包材でお届け。役に立つコラムも各種公開中。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-13.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-13.png',
    merchantName: 'クリケ',
    destinationURL: 'https://curike.com/',
    category: [7, 5],
    content:
      'オリジナルグッズ作成アプリ「クリケ」は、iPhoneケース/アクスタ/アクキー/トートバッグ等、お好きな写真で簡単に1個から作成可能。「クリケ」で家族や友達との思い出をカタチに。今なら商品代無料キャンペーン実施中！',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-14.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-14.svg',
    merchantName: 'ツクモネットショップ',
    destinationURL: 'https://shop.tsukumo.co.jp/',
    category: [5],
    content:
      '自作パソコンを通販で購入するなら、自作PC・パーツ・周辺機器などの専門店【TSUKUMO】。ゲームをしたい、高画質・高速処理のPCにしたいなど、様々なニーズに応え、必要なパーツや周辺機器を低価格、かつ豊富にそろえています。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-15.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-15.png',
    merchantName: 'GREEN FUNDING',
    destinationURL:
      'https://greenfunding.jp/?utm_medium=GREENFUNDING&utm_source=Paidy&utm_campaign=Paidy_2111',
    category: [7],
    content:
      '未来をちょっと先取りするガジェットが集まる、CCC（TSUTAYA）グループが運営するクラウドファンディングサイト。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-16.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-16.png',
    merchantName: 'BAKE THE ONLINE',
    destinationURL: 'https://bake-the-online.com/ ',
    category: [3],
    content:
      'BAKE INC.のお菓子を、オンラインでいつでもお取り寄せ。 定番のBAKE CHEESE TARTや人気商品のPRESS BUTTER SAND（プレス バターサンド）など、ベイクの商品をオンラインでも販売。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-17.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-17.png',
    merchantName: 'ひかりTVショッピング',
    destinationURL: 'https://shop.hikaritv.net/?sid=paidy&cid=paidy',
    category: [5, 3, 1, 4],
    content:
      '安心・安全のNTTぷらら通販 ひかりTVショッピング。パソコン・家電から、ホームキッチン・ブランド品まで、幅広いラインナップを取り揃えた総合オンラインストアです。ご注文は最短即日発送。各種セール・キャンペーンも随時開催中。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-18.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-18.svg',
    merchantName: 'PUNYUS ONLINE STORE',
    destinationURL: 'https://punyus.jp/lp/html/detail/paidy_11',
    category: [2],
    content:
      '渡辺直美がプロデュースするアパレルブランド。「女の子の持つ感情を表現する」をテーマに、ジャンルに捉われない様々なスタイルを提案。 豊富なサイズ展開が特徴で、フリーから6Lまでのサイズを取り揃えています。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-19.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-19.png',
    merchantName: 'EDWINオンラインモール',
    destinationURL: 'https://edwin-mall.jp/',
    category: [2],
    content:
      '定番デニムや暖パンツはもちろん、いつもキレイな黒パンツ「ハタラクロ」、使えるビジネスデニム「デニスラ」、オンでもオフでも使えるきれいめゴルフスタイル「EDWIN GOLF」など、「ありそうでなかった」を実現した多彩な商品をご用意しています。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-20.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-20.svg',
    merchantName: 'Rcawaii（アールカワイイ）',
    destinationURL:
      'https://www.rcawaii.com/lp/paidy/?rf=paidy&utm_source=paidy&utm_medium=paidy&utm_campaign=paidy_cashback2111',
    category: [2],
    content:
      '【女性向け洋服レンタルサービス】500ブランド以上、数十万点の洋服から、専属スタイリストが選ぶ全身コーデが借り放題。デート、女子会、オフィス、リモートワーク、着物、ドレス、コスプレ等利用シーン多数。最新トレンドアイテムが最短翌日に届きます。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-21.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-21.svg',
    merchantName: 'サマンサタバサ公式オンラインショップ',
    destinationURL: 'https://www.samantha.co.jp/shop/default.aspx',
    category: [2],
    content:
      'サマンサタバサ公式オンラインショップは『サマンサタバサ』『サマンサベガ』『サマンサタバサプチチョイス』『サマンサティアラ』『サマンサシルヴァ』『KINGZ』など、サマンサグループブランドのバッグやジュエリーを取り扱う公式通販サイトです。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-22.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-22.svg',
    merchantName: 'コジマネット',
    destinationURL: 'https://www.kojima.net/ec/index.html',
    category: [5],
    content:
      '家電のコジマ、公式通販サイト。新製品や人気家電がネット限定価格でお買い得。安心の長期保証無料進呈、ご購入後も全国の店舗で充実・安心のサポート。 お買い上げ金額税込3,000円以上で基本配送料無料、さらに大型商品は標準設置作業も無料でお届け。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-23.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-23.png',
    merchantName: '楽天RAXY',
    destinationURL: 'https://raxy.rakuten.co.jp/',
    category: [4],
    content:
      'わたしをもっと、好きになる。RAXYはコスメを毎月ご自宅にお届けするサブスクリプションサービス。人気ブランドとのコラボボックスやRAXYセレクトのボックスで、新しいコスメとの出会いをお届け。さらに、動画や記事、SNSで美容トレンドを発信中。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-24.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-24.svg',
    merchantName: 'MORE self LOVE',
    destinationURL: 'https://www.moreselflove.jp/',
    category: [2],
    content:
      '‘’自己愛‘’をテーマに、海外のgirls teenをイメージした洋服を展開。雑誌やTVなどのメディアに多数掲載された、話題のSHOPです。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-25.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-25.svg',
    merchantName: 'ME’VE the store',
    destinationURL: 'https://www.meve.jp/',
    category: [2],
    content:
      '韓国系アパレルセレクトショップ。リボンやフリルなど、cuteでsweetなデザインの洋服を取り揃えています。小物やバッグなどのアイテムも人気。ぜひサイトをチェックしてみてください。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-26.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-26.svg',
    merchantName: 'アンドシュエット公式オンラインショップ',
    destinationURL: 'https://online.and-chouette.jp/shop/default.aspx',
    category: [2],
    content:
      'トレンドを取り入れながら、より気軽に楽しめる魅力的な価格帯で、毎日のコーディネートを自由に、そして素敵に演出するブランド『アンドシュエット』公式通販サイト。',
  },
  {
    imgBg: '/campaign/merchant-banners/merchant-banner-27.png',
    imgLogo: '/campaign/merchant-logos/merchant-logo-27.svg',
    merchantName: 'FAVES BEAUTY',
    destinationURL: 'https://www.favesbeauty.com/',
    category: [4],
    content:
      '1人1人が持つ肌や瞳の色で導き出す、パーソナルカラーでえらべるコスメブランド。あなたの魅力を最大限に引き出すカラーコスメが揃っています。公式サイトにある独自の診断機能で、スマホで簡単にパーソナルカラー診断ができます。',
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: '総合通販' },
  { value: 2, label: 'ファッション' },
  { value: 3, label: 'グルメ' },
  { value: 4, label: '美容・健康・コンタクト' },
  { value: 5, label: '家電・インテリア・雑貨' },
  { value: 6, label: 'デジタルコンテンツ・ゲーム・カード' },
  { value: 7, label: 'その他サービス' },
];
