import React from 'react';
import BoxIndex from '../../../BoxIndex/BoxIndex';
import styles from './CampaignTitle.module.scss';

const CAMPAIGN_TITLE_CONTENTS = [
  {
    title: <strong>キャンペーン概要</strong>,
    content: (
      <>
        対象加盟店のうち2店舗以上で、ペイディの翌月あと払いもしくは3回あと払いでお買い物すると、2店舗のお買い物金額の合計が3,000円以上で500円キャッシュバック、8,000円以上で1,000円キャッシュバックします。
        <br />
        <br />
        ※キャンペーン期間中に上記条件でお買い物された方に、11月30日までにキャッシュバックを実施。
      </>
    ),
  },
  {
    title: <strong>キャンペーン期間</strong>,
    content: <strong>2021年11月1日（月）0:00～21日（日）23:59</strong>,
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        ・キャッシュバックはお一人様1回までとさせていただきます。
        <br />
        ・株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        <br />
        ・不正と判断された場合や購入のキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        <br />
        ・その他条件はキャッシュバック利用規約に準じます。
        <br />
        ・ペイディに関するお問い合わせは
        <a
          href="https://cs.paidy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディヘルプページ
        </a>
        へご連絡ください。
      </>
    ),
  },
];

const CampaignTitle = () => {
  return (
    <BoxIndex
      // title={''}
      sectionStyle={{ maxWidth: 1128 }}
    >
      <div className={styles.campaignWrapper}>
        {CAMPAIGN_TITLE_CONTENTS.map((campaign, i) => (
          <div key={i} className={styles.campaign}>
            <div className={styles.title}>{campaign.title}</div>
            <div className={styles.content}>{campaign.content}</div>
          </div>
        ))}
      </div>
    </BoxIndex>
  );
};

export default CampaignTitle;
