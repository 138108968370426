// @flow
/* eslint-disable react/no-danger */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import 'src/components/Layout/Layout.scss';
import { SITE_METADATA } from 'src/constants';
import CampaignTitle from 'src/components/Merchant/Campaign/CrossMerchantNovember2021/CampaignTitle';
import MerchantGrid from 'src/components/Merchant/Campaign/CrossMerchantNovember2021/MerchantGrid';
import CampaignFooter from 'src/components/Merchant/Campaign/CrossMerchantNovember2021/CampaignFooter';
import styles from './crossmerchantnovember2021.module.scss';

const Crossmerchantnovember2021 = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            ogImage
          }
        }
      }
    `
  );

  const { siteUrl, ogImage } = site.siteMetadata;

  return (
    <div>
      <Helmet
        title={SITE_METADATA.crossMerchantNovember2021.title}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: `description`,
            content: SITE_METADATA.crossMerchantNovember2021.description,
          },
          {
            property: `og:title`,
            content: SITE_METADATA.crossMerchantNovember2021.title,
          },
          {
            property: `og:description`,
            content: SITE_METADATA.crossMerchantNovember2021.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: 'twitter:image',
            content: `${siteUrl}${ogImage}`,
          },
          {
            name: `twitter:title`,
            content: SITE_METADATA.crossMerchantNovember2021.title,
          },
          {
            name: `twitter:description`,
            content: SITE_METADATA.crossMerchantNovember2021.description,
          },
          {
            property: 'og:url',
            content: `${siteUrl}`,
          },
          {
            property: 'og:image',
            content: `${siteUrl}${ogImage}`,
          },
          {
            property: 'og:image:secure',
            content: `${siteUrl}${ogImage}`,
          },
          {
            property: 'og:image:type',
            content: `image/png`,
          },
        ]}
        script={[
          {
            async: 'async',
            defer: 'defer',
            innerHTML: `
            (function(d){
              var l = d.createElement("link");
              var s = d.getElementsByTagName("script")[0];
              l.rel = "stylesheet";
              l.href = "https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700,900&display=swap&subset=japanese";
              s.parentNode.insertBefore(l, s);
            })(document);
            `,
          },
        ]}
      />
      {/* <Header location={location} /> */}
      <div className={styles.topBanner} />
      <div className={styles.campaignDetail}>
        <div className={styles.campaignDetailInner}>
          <div className={styles.upperText}>
            <p>下記に掲載されている対象ショップ2店舗以上で</p>
            <p>ペイディを利用してお買い物すると、</p>
            <p className={styles.pinkText}>最大1,000円キャッシュバック！</p>
          </div>
          <br />
          <div className={styles.belowText}>
            <p>気になるショップでお得にアイテムをGETして、</p>
            <p>新しい秋の楽しみ方を見つけませんか？</p>
          </div>
        </div>
      </div>
      <div className={styles.scrollDown}>
        <img
          src={require('src/images/campaign-winter-crossmerchant-2109/scroll-down.png')}
          width={32}
          height={64}
          alt={'scrolldown'}
        />
      </div>
      <CampaignTitle />
      <div className={styles.merchantTitle}>
        <p className={styles.pinkText}>秋の新発見キャンペーン</p>
        <p>対象ショップ</p>
      </div>
      <MerchantGrid />
      <CampaignFooter />
    </div>
  );
};

export default Crossmerchantnovember2021;
