import React, { useState } from 'react';
import cx from 'classnames';
import { Chip } from '@material-ui/core';

import styles from './MerchantGrid.module.scss';
import { MERCHANTS, TAGS } from './MerchantGridData';

const DEFAULT_TAG = TAGS[0].value;

const useMerchantList = () => {
  const [activeCategory, setActiveCategory] = useState([DEFAULT_TAG]);
  const [merchants, setMerchants] = useState(MERCHANTS);

  function chooseCategory(category) {
    // Select the default tag then show all, can't deselect default tag
    if (category === DEFAULT_TAG) {
      setActiveCategory([DEFAULT_TAG]);
      setMerchants(MERCHANTS);

      return;
    }

    // Deselect the tag
    if (activeCategory.includes(category)) {
      // Reset to default tag if deselect the last tag
      if (activeCategory.length === 1) {
        setActiveCategory([DEFAULT_TAG]);
        setMerchants(MERCHANTS);

        return;
      }

      const newCategories = activeCategory.filter((tag) => tag !== category);
      const newMerchants = MERCHANTS.filter((merchant) =>
        merchant.category.some((merc) => newCategories.includes(merc))
      );

      setActiveCategory(newCategories);
      setMerchants(newMerchants);

      return;
    }

    // Select a new tag
    const newCategories = [
      ...activeCategory.filter((tag) => tag !== DEFAULT_TAG),
      category,
    ];
    const newMerchants = MERCHANTS.filter((merchant) =>
      merchant.category.some((merc) => newCategories.includes(merc))
    );

    setActiveCategory(newCategories);
    setMerchants(newMerchants);
  }

  return {
    categories: TAGS,
    merchants,
    activeCategory,
    chooseCategory,
  };
};

const MerchantGrid = () => {
  const {
    categories,
    merchants,
    activeCategory,

    chooseCategory,
  } = useMerchantList();

  return (
    <div className={styles.merchantGridWrapper}>
      <MerchantFilterer
        activeCategory={activeCategory}
        categories={categories}
        chooseCategory={chooseCategory}
      />
      <div className={styles.merchantGrid}>
        {merchants.map((m, i) => (
          <MerchantCard key={i} {...m} />
        ))}
      </div>
    </div>
  );
};

const MerchantFilterer = ({ activeCategory, categories, chooseCategory }) => {
  return (
    <div className={styles.merchantFilterer}>
      {categories.map((cate) => (
        <Chip
          key={cate.value}
          label={cate.label}
          onClick={() => chooseCategory(cate.value)}
          clickable
          color="secondary"
          className={cx(styles.filterButton, {
            [styles.activeFilterButton]: activeCategory.includes(cate.value),
          })}
        />
      ))}
    </div>
  );
};

const MerchantCard = ({
  imgBg,
  imgLogo,
  merchantName,
  destinationURL,
  content,
}) => {
  return (
    <div className={styles.merchantCard}>
      <div className={styles.merchantCardImageBg}>
        <a href={destinationURL} target="_blank" rel="noopener noreferrer">
          <img src={imgBg} alt={merchantName} />
        </a>
      </div>
      <div className={styles.merchantCardImageLogo}>
        <img src={imgLogo} alt={merchantName} />
      </div>
      <div className={styles.merchantCardContent}>
        <h4>{merchantName}</h4>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default MerchantGrid;
